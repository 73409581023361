<template>
  <div>
    <div
      v-if="user.role === 'admin'"
      class="d-flex flex-wrap"
    >
      <b-card
        v-for="(repo, index) in repositories"
        :key="index"
        :class="{ 'selected-card': isSelected(repo) }"
        class="cursor-pointer mb-2 mr-2"
        @click="selectRepository(repo)"
      >
        <div>{{ repo.name }}</div>
        <!-- Add other repository details as needed -->
      </b-card>
    </div>
    <b-form-group
      label="Référence de colis"
      label-for="vi-first-name"
    >
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="MaximizeIcon" />
        </b-input-group-prepend>
        <b-form-input
          ref="select"
          v-model="ref"
          placeholder="Scanner un colis"
          @change="checkOrder"
        />
      </b-input-group>
    </b-form-group>
    <!-- table -->
    <div class="d-flex justify-content-between">
      <div
        v-if="isLoading === true"
        class="text-center mb-2"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
      </div>
      <b-card>
        <h4>colis à valider : {{ orders.length }}</h4>
        <vue-good-table
          :columns="columnsToTransfer"
          :rows="orders"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm ,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              <span> {{ props.row.id }}</span><br>
              <span
                v-if="props.row.is_an_exchange"
                style="color:red ; font-weight: bold;"
              > Echange </span>
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="CheckSquareIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                  color="green"
                  @click="addItem(props.row)"
                />
                <b-tooltip
                  title="Ajouter"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />

              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-card class="ml-1">
        <h4>colis validé : {{ selected.length }}</h4>
        <vue-good-table
          :columns="columnsTransfer"
          :rows="selected"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              <span> {{ props.row.id }}</span><br>
              <span
                v-if="props.row.is_an_exchange"
                style="color:red ; font-weight: bold;"
              > Echange </span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BPagination, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect, BTooltip, BSpinner,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import transfere from '@/assets/audio/transfere.mp3'
import errorVoice from '@/assets/audio/error.mp3'
import alreadyScanned from '@/assets/audio/colis-deja-scanne.mp3'

export default {
  name: 'CreateRoadmap',
  components: {
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    BTooltip,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      date: '01-10-2021',
      status: '',
      pageLength: 10,
      dir: false,
      columnsToTransfer: [
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'BL',
          field: 'id',
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher client',
          },
        },
        {
          label: 'Fournisseur',
          field: 'supplier.username',
          filterOptions: {
            enabled: true,
            placeholder: 'Fournisseur',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher gouvernorat',
          },
        },
        {
          label: 'Délégation',
          field: 'delegation',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher délégation',
          },
        },
        {
          label: 'Adresse',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher adresse',
          },
        },
        {
          label: 'tel',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher tel',
          },
        },
      ],
      columnsTransfer: [
        {
          label: 'BL',
          field: 'id',
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher client',
          },
        },
        {
          label: 'Fournisseur',
          field: 'supplier.username',
          filterOptions: {
            enabled: true,
            placeholder: 'Fournisseur',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher gouvernorat',
          },
        },
        {
          label: 'Délégation',
          field: 'delegation',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher délégation',
          },
        },
        {
          label: 'Adresse',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher adresse',
          },
        },
        {
          label: 'tel',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher tel',
          },
        },
      ],
      role: '',
      orders: [],
      selected: [],
      searchTerm: '',
      ref: '',
      user: {},
      repository: {},
      repositories: [],
      transfere,
      errorVoice,
      alreadyScanned,
    }
  },
  created() {
    this.user = storeAuth.state.user
    if (this.user.role === 'manager') {
      this.getOrdersToTransfer()
    }
    this.getRepositories()
  },
  mounted() {
    this.$refs.select.focus()
  },
  methods: {
    isSelected(repository) {
      return this.repository && this.repository.id === repository.id
    },
    getRandomColor(index) {
      const colors = ['#FF5733', '#33FF57', '#5733FF', '#FF33D1', '#33D1FF']
      const colorIndex = index % colors.length
      return colors[colorIndex]
    },
    selectRepository(repository) {
      this.repository = repository
      this.getOrdersToTransfer()
    },
    async checkOrder() {
      const existingOrder = this.selected.find(item => {
        if (item.supplier.id === 504) {
          return item.bestway_barcode === Number(this.ref) || item.id === Number(this.ref)
        }
        return item.id === Number(this.ref)
      })

      if (existingOrder) {
        this.withTitle('Colis déjà scanné sur la liste sélectionnée')
        this.playAudio(alreadyScanned)
      } else {
        const searchedItem = this.orders.find(element => {
          if (element.supplier.id === 504) {
            return element.bestway_barcode === Number(this.ref) || element.id === Number(this.ref)
          }
          return element.id === Number(this.ref)
        })

        if (searchedItem) {
          this.addItem(searchedItem).then(() => {
            this.ref = ''
            this.playAudio(transfere)
          })
        } else {
          this.withTitle('Colis non exist')
          this.playAudio(errorVoice)
        }
      }
    },
    async playAudio(payload) {
      const voice = new Audio(payload)
      voice.addEventListener('canplaythrough', () => {
        voice.play()
      })
    },
    async delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async addItem(item) {
      this.selected.push(item)
      const i = this.orders.findIndex(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === item.bestway_barcode
        }
        return element.id === item.id
      })
      let repository = null
      if (storeAuth.state.user.role === 'admin') {
        repository = this.repository.id
      } else {
        repository = storeAuth.state.user.repository
      }
      await axios.post('/api/orders/validate-transfer-orders/', {
        order_id: item.id,
        to_repository: repository,
      })
      this.orders.splice(i, 1)
      this.showToast('success', 'top-center', 'Status modifié avec succés')
    },
    async getRepositories() {
      const { data } = await axios.get('/api/repositories/')
      this.repositories = data
    },
    withTitle(text) {
      this.$swal({
        title: 'Alerte',
        icon: 'warning',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.ref = ''
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async getOrdersToTransfer() {
      this.isLoading = true
      this.selected = []
      let repository = null
      if (storeAuth.state.user.role === 'admin') {
        repository = this.repository.id
      } else {
        repository = storeAuth.state.user.repository
      }
      const { data } = await axios.get('/api/transfer-vouchers/get-orders-with-is-transfer-false/', {
        params: {
          to_repository: repository,
        },
      })
      this.orders = data
      this.isLoading = false
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.selected-card {
  background-color: rgb(0, 219, 235);
  /* Add any other styles for the selected card if needed */
}
</style>
